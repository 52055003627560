import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Info from '../views/Info.vue'

import { auth } from '../firebase'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home

  },
  {
    path: '/info',
    name: 'Info',
    component: Info

  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/toestellen',
    name: 'Toestellen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Toestellen.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usermanagement',
    name: 'Users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Usermanagement.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admintools',
    name: 'Admin tools',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminTools.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/indexcreator',
    name: 'Index Creator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexCreator.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/itemtoevoegen',
    name: 'Item Toevoegen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ItemToevoegen.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

var userisloggedin = false
// navigation guard to check for logged in users
auth.onAuthStateChanged(async function (user) {
  console.log(user)
  if (user) {
    userisloggedin = true
    console.log('Whoop! user is logged in!')
    store.dispatch('setLoggedIn', user)
  } else {
    console.log('No User: redicecting to login page!')
    userisloggedin = false
  }
  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    if (requiresAuth && !userisloggedin) {
      next('/login')
    } else {
      next()
    }
  })
})

/*

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  var userisloggedin = false

   if (requiresAuth && !userisloggedin) {
    next('/login')
  } else {
    next()
  }

}) */

export default router
