import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { auth } from './firebase'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

// https://www.npmjs.com/package/vue-toastr-2
import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'

// JSON VIEWER
import JsonTree from 'vue-json-tree'
Vue.component('json-tree', JsonTree)

function patchRouterMethod (router, methodName) {
  router['old' + methodName] = router[methodName]
  router[methodName] = async function (location) {
    return router['old' + methodName](location).catch((error) => {
      if (error.name === 'NavigationDuplicated') {
        return this.currentRoute
      }
      throw error
    })
  }
}

patchRouterMethod(router, 'push')
patchRouterMethod(router, 'replace')

Vue.config.productionTip = false

window.toastr = require('toastr')

Vue.use(VueToastr2)

let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
