import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

import firebaseSecret from './firebase.secret.json'

firebase.initializeApp(firebaseSecret)

// firebase.functions().useEmulator('localhost', 5001)

// utils
const fireb = firebase
// const functions = firebase.app().functions('europe-west1')
// const functions = firebase.app().functions()
const functions = firebase.app().functions()
const db = firebase.firestore()
const firestoreFieldvalue = firebase.firestore.FieldValue
const auth = firebase.auth()
const storage = firebase.storage()
const storageRef = firebase.storage().ref('images')
// collection references
const devicesCollection = db.collection('TEST')
const usersCollection = db.collection('usersadmin')

// export utils/refs
export {
  fireb,
  functions,
  db,
  firestoreFieldvalue,
  auth,
  storage,
  storageRef,
  devicesCollection,
  usersCollection
}
