import Vue from 'vue'
import Vuex from 'vuex'
// import Vuex, { store } from 'vuex'

import * as fb from './firebase'
import router from './router/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
      claims: {},
      googleAccessToken: ''
    },
    devices: [],
    unsubscribers: [],
    workingSchool: '',
    activeModalId: '',
    // https://codeseven.github.io/toastr/demo.html
    toastrOptions: {
      closeButton: true,
      debug: true,
      newestOnTop: true,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      onclick: null,
      showDuration: '300',
      hideDuration: '2000',
      timeOut: '8000',
      extendedTimeOut: '2000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      maxOpened: 1
    },
    toastrOptionsHardToast: {
      closeButton: true,
      debug: true,
      newestOnTop: true,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      onclick: null,
      showDuration: '20000',
      hideDuration: '0',
      timeOut: '0',
      extendedTimeOut: '0',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut'
    },
    // INDIEN AANPASSEN, OOK BIJ HELPERS IN CLOUDFUNCTIE AANPASSEN
    case_fold_options: {
      title: { config: 'case_fold', forEndUsers: 'Titel' },
      merk: { config: 'case_fold', forEndUsers: 'Merk' },
      typeserie: { config: 'case_fold', forEndUsers: 'Type/serie' },
      serienummer: { config: 'case_fold', forEndUsers: 'Serienummer' },
      notes: { config: 'case_fold', forEndUsers: 'Opmerkingen' },
      sgassetid: { config: 'case_fold', forEndUsers: 'SGFV-ID' },
      locatie: { config: 'case_fold', forEndUsers: 'Locatie' },
      uitgeleendaan: { config: 'case_fold', forEndUsers: 'Uitgeleend aan' },
      defectopmerking: { config: 'case_fold', forEndUsers: 'Defect: opmerking' },
      addedBy_email: { config: 'case_fold', forEndUsers: 'Toegevoegd door: ...(email)' },
      lastEditBy_email: { config: 'case_fold', forEndUsers: 'Laatst bewerkt door: ...(email)' },
      orgUnitPath: { config: 'case_fold', forEndUsers: 'Ingeschreven in organisatie...' },
      inbedrijfstelling: { config: 'case_fold', forEndUsers: 'Inbedrijfstelling (yyyy-mm-dd)' }
    },
    bulk_editable_fields: {
      title: { config: 'case_fold', forEndUsers: 'Titel' },
      uitgeleendaan: { config: 'case_fold', forEndUsers: 'Uitgeleend aan' },
      defect: { config: 'case_fold', forEndUsers: 'Defect (gebruik true of false' },
      teverzekeren: { config: 'case_fold', forEndUsers: 'Te verzekeren (gebruik true of false)' },
      vastaanmuur: { config: 'case_fold', forEndUsers: 'Hangt vast aan muur (gebruik true of false)' },
      huurlease: { config: 'case_fold', forEndUsers: 'Huur of lease (gebruik true of false)' },
      arbeidsmiddel: { config: 'case_fold', forEndUsers: 'Arbeidsmiddel (gebruik true of false)' },
      defectopmerking: { config: 'case_fold', forEndUsers: 'Defect: opmerking' },
      leverancier: { config: 'case_fold', forEndUsers: 'Leverancier' },
      locatie: { config: 'case_fold', forEndUsers: 'Locatie' },
      prijs: { config: 'case_fold', forEndUsers: 'Prijs (Enkel cijfer opgeven)' },
      inbedrijfstelling: { config: 'case_fold', forEndUsers: 'Inbedrijfstelling (formaat yyyy-mm-dd)' },
      uitbedrijfstelling: { config: 'case_fold', forEndUsers: 'Uitbedrijfstelling (formaat yyyy-mm-dd)' },
      maandengarantie: { config: 'case_fold', forEndUsers: 'Aantal maanden garantie (enkel cijfer invoeren)' },
      notes: { config: 'case_fold', forEndUsers: 'Opmerkingen' },
      orgUnitPath: { config: 'case_fold', forEndUsers: 'Organisatie (Opgepast! Hiermee kan je toestellen verhuizen zodat je er zelf niet meer aankan!)' },
      merk: { config: 'case_fold', forEndUsers: 'Merk (Opgepast! Niet zonder meer gebruiken. Dit heeft gevolgen indien gebruik bij Chromebooks.)' },
      typeserie: { config: 'case_fold', forEndUsers: 'Type/serie (Opgepast! Niet zonder meer gebruiken. Dit heeft gevolgen indien gebruik bij Chromebooks.)' }
    },
    exportableFields: {
      vastaanmuur: { field: 'vastaanmuur', forEndUsers: 'Vast aan de muur' },
      uitgeleend: { field: 'uitgeleend', forEndUsers: 'Uitgeleend' },
      uitgeleendaan: { field: 'uitgeleendaan', forEndUsers: 'Uitgeleend aan' },
      title: { field: 'title', forEndUsers: 'Titel' },
      sgassetid: { field: 'sgassetid', forEndUsers: 'SGFV-ID' },
      school: { field: 'school', forEndUsers: 'School' },
      locatie: { field: 'locatie', forEndUsers: 'Locatie' },
      teverzekeren: { field: 'teverzekeren', forEndUsers: 'Te verzekeren' },
      devicetype: { field: 'devicetype', forEndUsers: 'Soort toestel' },
      huurlease: { field: 'huurlease', forEndUsers: 'Huur, lease of huurkoop' },
      defect: { field: 'defect', forEndUsers: 'Defect' },
      merk: { field: 'merk', forEndUsers: 'Merk' },
      typeserie: { field: 'typeserie', forEndUsers: 'Type / Serie' },
      serienummer: { field: 'serienummer', forEndUsers: 'Serienummer' },
      leverancier: { field: 'leverancier', forEndUsers: 'Leverancier' },
      prijs: { field: 'prijs', forEndUsers: 'Prijs' },
      inbedrijfstelling: { field: 'inbedrijfstelling', forEndUsers: 'Inbedrijfsstelling' },
      uitbedrijfstelling: { field: 'uitbedrijfstelling', forEndUsers: 'Uitbedrijfstelling' },
      maandengarantie: { field: 'maandengarantie', forEndUsers: 'Maanden garantie' },
      notes: { field: 'notes', forEndUsers: 'Opmerkingen' },
      defectopmerking: { field: 'defectopmerking', forEndUsers: 'Opmerking defect' },
      voorraad: { field: 'voorraad', forEndUsers: 'Voorraad' },
      arbeidsmiddel: { field: 'arbeidsmiddel', forEndUsers: 'Arbeidsmiddel' },
      addedBy_email: { field: 'addedBy_email', forEndUsers: 'Toegevoegd door: ...(email)' },
      lastEditBy_email: { field: 'lastEditBy_email', forEndUsers: 'Laatst bewerkt door: ...(email)' },
      added: { field: 'added', forEndUsers: 'Tijdstip (tekst) waarop item toegevoegd werd.' },
      orgUnitPath: { field: 'orgUnitPath', forEndUsers: 'Ingeschreven in organisatie...' },
      docId: { field: 'docId', forEndUsers: 'DocId uit database (voor technische problemen)' }

    }
  },

  getters: {
    user (state) {
      return state.user
    },
    devices (state) {
      return state.devices
    },
    usersadmin (state) {
      return state.usersadmin
    },
    workingSchool (state) {
      return state.workingSchool
    },
    activeModalId (state) {
      return state.activeModalId
    },
    unsubscribers (state) {
      return state.unsubscribers
    },
    toastrOptions (state) {
      return state.toastrOptions
    },
    toastrOptionsHardToast (state) {
      return state.toastrOptionsHardToast
    },
    case_fold_options (state) {
      return state.case_fold_options
    },
    exportableFields (state) {
      return state.exportableFields
    },
    bulk_editable_fields (state) {
      return state.bulk_editable_fields
    }
  },

  mutations: {
    SET_LOGGED_IN (state, value) {
      console.log('new value is %s', value)
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      /* console.log('user data: ')
      console.log(data) */
      state.user.data = data
    },
    SET_USER_CLAIMS (state, data) {
      /* console.log('user data: ')
      console.log(data) */
      console.log('ADDING USER CLAIMS...', data)
      state.user.claims = data
    },
    SET_GOOGLE_ACCESS_TOKEN (state, data) {
      /* console.log('user data: ')
      console.log(data) */
      console.log('ADDING USER GOOGLE ACCESS TOKEN...', data)
      state.user.googleAccessToken = data
    },
    SET_DEVICES (state, val) {
      console.log('DEVICES ARE BEING SET...')
      state.devices = val
    },
    SET_WORKINGSCHOOL (state, school) {
      console.log('WORKING SCHOOL IS BEING SET AS: ' + school)
      state.workingSchool = school
    },
    SET_ACTIVEMODALID (state, modalId) {
      console.log('ACTIVE MODAL ID IS BEING SET AS: ' + modalId)
      if (!modalId) { modalId = '' }
      state.activeModalId = modalId
    },
    ADD_UNSUBSCRIBER (state, newUnsubscriber) {
      // console.log('ADDING UNSUBSCRIBER ' + newUnsubscriber)
      if (state.unsubscribers.indexOf(newUnsubscriber) <= -1) {
        state.unsubscribers.push(newUnsubscriber)
      } else {
        console.log('Unsubscriber skipped... already existed.')
      }
    },
    UNSUBSCRIBE_ALL (state) {
      state.unsubscribers.forEach(unsubscriber => unsubscriber())
      console.log('All unsubscribed...')
      state.unsubscribers = []
    }
  },

  actions: {
    async login ({ commit, dispatch }, routeObj) {
      console.log("Let's login!")
      // sign user in
      var provider = new fb.fireb.auth.GoogleAuthProvider()
      provider.addScope('https://www.googleapis.com/auth/spreadsheets')
      fb.auth.signInWithPopup(provider).then(async (result) => {
        var credential = result.credential

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken
        console.log('Google Token found! ', token)
        commit('SET_GOOGLE_ACCESS_TOKEN', token)
        // The signed-in user info.
        var user = result.user

        dispatch('setLoggedIn', user)
        var tokenresult = await fb.auth.currentUser.getIdTokenResult()
        var customclaims = tokenresult.claims

        console.log('customclaims', customclaims)
        commit('SET_USER_CLAIMS', customclaims)
        if (routeObj) {
          router.push(routeObj.route).catch(() => {})
        } else {
          router.push('/info').catch(() => {})
        }
      }).catch((error) => {
        alert(error)
        console.log(error)
        commit('SET_USER', null)
      })

      /*
      const { user } = await fb.auth.signInWithPopup(provider)
      // fetch user profile and set in state
      console.log('USER', user)
      if (user) {
        var tokenresult = await fb.auth.currentUser.getIdTokenResult()
        console.log('TOKENRESULT', tokenresult)
        var customclaims = tokenresult.claims
        // https://gist.github.com/jamestalmage/2d8d1d5c42157caf349e
        customclaims.tokenObject = tokenresult.credential.accessToken

        console.log('CUSTOMCLAIMS', JSON.stringify(customclaims))

        commit('SET_USER_CLAIMS', customclaims)
        await dispatch('setLoggedIn', user)
        */
      /*  router.push('/info')
      } else {
        commit('SET_USER', null)
      } */
    },
    async setLoggedIn ({ commit, dispatch }, user) {
      commit('SET_USER', user)
      commit('SET_LOGGED_IN', true) // user !== null)
      try { await fb.db.collection('usersadmin').doc(await user.uid).update({ photoURL: user.photoURL }, { merge: true }) } catch (e) { console.log('unable to update image...'); console.log(e) }

      var tokenresult = await fb.auth.currentUser.getIdTokenResult()
      var customclaims = tokenresult.claims

      commit('SET_USER_CLAIMS', customclaims)
    },
    async logout ({ commit }) {
      console.log('Logging you out, dude!')
      // unsubscribe()
      commit('SET_DEVICES', [])
      await commit('UNSUBSCRIBE_ALL')
      console.log('unsubscribed!')
      await fb.auth.signOut()
      commit('SET_USER', null)
      commit('SET_LOGGED_IN', false) // user !== null)
      commit('SET_USER_CLAIMS', {})
    },
    async startFilter ({ commit }) {
      await commit('UNSUBSCRIBE_ALL')
      console.log('unsubscribed!')
    },
    async readData ({ commit }) {
      console.log('GETTING YOUR DEVICES, SIR')
      var newUnsubscriber = fb.devicesCollection.onSnapshot((snapshot) => {
        const devices = []
        snapshot.forEach((doc) => {
          const device = doc.data()
          device.id = doc.id
          devices.push(device)
        })
        console.log('Devices fetched!')
        console.log(devices)
        commit('SET_DEVICES', devices)
      })
      commit('ADD_UNSUBSCRIBER', newUnsubscriber)
    },
    async removeData ({ commit, dispatch }, obj) {
      // LIST ALL IMAGES REMOVE, THEM ONE BY ONE
      console.log('Removing all images from storage...')
      try {
        var doc = await fb.db.collection(obj.school).doc(obj.docid).get()

        var imagesprocessed = doc.data().imagesprocessed
        imagesprocessed.forEach(async function (image) {
          if (!image.gs) { console.log('Geen bucket-file... skipping (chromebook?)'); return false }

          // GSfile weg doen
          var linkToBucket = image.gs.replace('gs://sg-inventaris-elektronica.appspot.com/images/', '')
          await fb.storageRef.child(linkToBucket).delete()
          console.log('file %s deleted!', linkToBucket)

          // GS_thumb file wegdoen
          var linkToThumb = image.gs_thumb.replace('gs://sg-inventaris-elektronica.appspot.com/images/', '')
          await fb.storageRef.child(linkToThumb).delete()
          console.log('thumb_file %s deleted!', linkToThumb)

          // GS_resized file wegdoen
          var linkToResized = image.gs_resized.replace('gs://sg-inventaris-elektronica.appspot.com/images/', '')
          await fb.storageRef.child(linkToResized).delete()
          console.log('Resized_file %s deleted!', linkToResized)
        })
      } catch (e) {
        console.log('file was not removed...')
      }

      console.log('Now removing ' + obj.docid + ' from database...')
      try {
        await fb.db.collection(obj.school).doc(obj.docid).delete()
        console.log('doc successfully deleted...')
      } catch (e) {
        console.log('Document NOT deleted... ')
        console.log(e)
      }
    },
    async removeImageFromDocument ({ commit, dispatch }, obj) {
      // GET DOC and get images object > remove image with correct index and delete matching files...

      // var obj = { docId: docId, school: school, imageIndex: index }
      console.log('Removing correct image from storage...')
      try {
        var doc = await fb.db.collection(obj.school).doc(obj.docId).get()

        var imagesprocessed = doc.data().imagesprocessed
        imagesprocessed.forEach(async function (image, index) {
          if (index !== obj.imageIndex) { return false }
          if (!image.gs) { console.log('nog bucket-file... skipping (chromebook?)'); return false }
          var linkToBucket = image.gs.replace('gs://sg-inventaris-elektronica.appspot.com/images/', '')
          await fb.storageRef.child(linkToBucket).delete()
          console.log('file %s deleted!', linkToBucket)
          var linkToThumb = image.gs_thumb.replace('gs://sg-inventaris-elektronica.appspot.com/images/', '')
          await fb.storageRef.child(linkToThumb).delete()
          console.log('file %s deleted!', linkToThumb)
        })
      } catch (e) {
        console.log('file was not removed...')
      }
      console.log('removing item from firestoreDB')
      if (!doc.data().imagesprocessed[obj.imageIndex].gs) { console.log('Deze gaan we laten staan... geen user image.'); return false }
      await fb.db.collection(obj.school).doc(obj.docId).update({
        imagesprocessed: fb.firestoreFieldvalue.arrayRemove(doc.data().imagesprocessed[obj.imageIndex])
      })
    },
    async setSavedSchool ({ commit, dispatch }, school) {
      console.log('Working school is being saved...')
      commit('SET_WORKINGSCHOOL', school)
    },
    async setActiveModalId ({ commit, dispatch }, activeModalId) {
      console.log('Active modal ID is being saved...')
      commit('SET_ACTIVEMODALID', activeModalId)
    },
    async addUnsubscriber ({ commit, dispatch }, newUnsubscriber) {
      console.log('Adding a new unsubscriber')
      commit('ADD_UNSUBSCRIBER', newUnsubscriber)
    },
    async controleerGarantie ({ commit, dispatch }, obj) {
      if (!obj.inbedrijfstelling || !obj.maandengarantie) {
        return 'insufficient_data'
      }
      const date = new Date(obj.inbedrijfstelling)
      var d = date.getDate()
      var garantie = obj.maandengarantie
      var nu = new Date()
      date.setMonth(date.getMonth() + +garantie)
      if (date.getDate() !== d) {
        date.setDate(0)
      }
      if (date > nu) {
        return true
      } else {
        return false
      }
    },
    async caseFoldDoc ({ commit, dispatch }, doc) {
      doc.lastEditBy_email = this.state.user.data.email
      doc.lastEditBy_userid = this.state.user.data.uid
      doc.lastEditBy_displayname = this.state.user.data.displayName
      doc.lastEditBy_photoUrl = this.state.user.data.photoURL
      doc.lastEditAt = new Date().toLocaleString()

      if (this.state.case_fold_options != null) {
        for (var field in this.state.case_fold_options) {
          if (Object.prototype.hasOwnProperty.call(this.state.case_fold_options, field)) {
            switch (this.state.case_fold_options[field].config) {
              case 'case_fold':
                if (Object.prototype.hasOwnProperty.call(doc, field) && Object.prototype.toString.call(doc[field]) === '[object String]') {
                  var casefolded = doc[field].normalize('NFKC').toLowerCase().toUpperCase().toLowerCase()
                  if (isNaN(casefolded)) {
                    casefolded = null
                  }
                  doc[field.concat('_insensitive')] = casefolded
                }
                break
            }
          }
        }
      }

      return doc
    },
    async urlify ({ commit, dispatch }, text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g
      return text.replace(urlRegex, function (url) {
        return '<br><b><a class="btn btn-primary" target="_blank" href="mailto:jaspercuvelier@sgfv.be?subject=INDEX%20GENEREREN&body=Hallo%0D%0AIs%20het%20mogelijk%20om%20deze%20index%20toe%20te%20voegen%20aan%20het%20firebase%20project%3F%0D%0A' + encodeURIComponent(url) + '">Mail naar ICTCO </a></b><br>' +
        '<br><br><i>Als applicatiebeheerder kan je <a href="' + url + '" target="blank"><b> deze link </b> </a>gebruiken.</i><br>'
      })
      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    },
    async getUrls ({ commit, dispatch }, text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g
      return text.replace(urlRegex, function (url) {
        return '<b><a class="btn btn-primary" href="' + url + '" target="blank">Naar firebase om index te maken</a></b><br>'
      })
      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }

  }
})

export default store
