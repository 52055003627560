<template >
  <div id="app" class="container mt-1">
    <!--<div class="alert alert-danger">Er is momenteel een probleem bij het <b>toevoegen</b> van nieuwe toestellen. Dit werkje dus even uitstellen...</div>-->
    <div class="customnav d-flex flex-row flex-nowrap w-100 align-items-center">
      <div class="imgholder">
      <img src="./assets/logo.png" height="100"/>
      </div>
      <div class="navholder align-items-center">

      <ul class="nav nav-pills justify-content-end align-items-center">
        <li class="nav-item">
      <router-link class="nav-link"  to="/"><span class="material-icons">
home
</span> Home</router-link>
        </li>
        <li class="nav-item">
      <router-link class="nav-link" active-class="active" v-if="user.loggedIn" to="/info"><span class="material-icons">
info
</span> Info</router-link>
        </li>
        <li class="nav-item">
      <router-link class="nav-link" active-class="active" v-if="user.loggedIn && user.claims.provisioned" to="/toestellen"><span class="material-icons">
format_list_bulleted
</span> Toestellen</router-link>
        </li>
        <li class="nav-item">
      <router-link class="nav-link" active-class="active" v-if="user.loggedIn && user.claims.provisioned" to="/itemtoevoegen"><span class="material-icons">
add_circle
</span> Toevoegen</router-link>
        </li>
                <li class="nav-item">
      <router-link class="nav-link" active-class="active" v-if="user && user.claims && user.claims.superuser"  to="/usermanagement"><span class="material-icons">
people
</span> Users</router-link>
        </li>
         <li class="nav-item">
      <router-link class="nav-link" active-class="active" v-if="user && user.claims && user.claims.superuser"  to="/admintools"><span class="material-icons">
admin_panel_settings
</span> Admin Tools</router-link>
        </li>
                 <li class="nav-item">
      <router-link class="nav-link" active-class="active" v-if="user && user.claims && user.claims.superuser"  to="/indexcreator"><span class="material-icons">
settings_applications
</span> IndexCreator</router-link>
        </li>
        <li class="nav-item">
      <router-link class="nav-link" active-class="active" v-if="!user.loggedIn" to="/login"><span class="material-icons">
login
</span> Login</router-link>
        </li>
        <li class="nav-item">
      <router-link v-if="user.loggedIn" active-class="active" class="nav-link" to="/login"><span class="material-icons">
logout
</span> Logout</router-link>
        </li>
      </ul>
       </div>
</div>
    <router-view/>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #233444;
}
:disabled:hover{
  cursor: not-allowed
}
</style>

<script>
// @ is an alias to /src

import { mapGetters } from 'vuex'
export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user'
    })
  },
  created () {
    // console.clear()
  }
}
</script>
